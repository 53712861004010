
import { Box, Button, Card, CardContent, Grid, Sheet, Typography } from "@mui/joy";
import { BoxProps } from "@mui/system";
import { useAuthContext } from "../Context/AuthContext";
import AspectRatio from '@mui/joy/AspectRatio';
import { CMember } from "../Model";
import { relativeDate } from "../utils";
import { Link } from "react-router-dom";



export function Home(props: BoxProps) {

  const {authenticated, authGoogle, memberships, auth, setAuthenticated} = useAuthContext();
 

  return ( 
    
<div style={{}}>
  <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      
      position:"absolute"
    }}>

      {authenticated?
<Grid
  container
  spacing={{ xs: 2, md: 3 }}
  padding={{ xs: 1, md: 4 }}
  columns={{ xs: 1, sm: 1, md: 2 }}
  sx={{ flexGrow: 1 }}
>
  {Array.from(memberships.map((membership, index) => (
    <Grid xs={1} sm={1} md={2} key={index}>
      <Link to={`/c/${membership.community._id}/members`} style={{ 
        textDecoration: 'none', 
       
        }}>
        <CommunitySummaryCard member={membership} />
      </Link>
    </Grid>
  )))}
</Grid>: undefined}

</Box>
</div>
   );
}


export default function CommunitySummaryCard( { member }: { member: CMember } ) {


    
  return (

    <Box
      sx={{
        width: '100%',
        position: 'relative',
        overflow: { xs: 'auto', sm: 'initial' },
        
      }}
    >
   
      <Card
        orientation="horizontal"
        sx={{
          width: '100%',
          flexWrap: 'wrap',
          [`& > *`]: {
            '--stack-point': '500px',
            minWidth:
              'clamp(0px, (calc(var(--stack-point) - 2 * var(--Card-padding) - 2 * var(--variant-borderWidth, 0px)) + 1px - 100%) * 999, 100%)',
          },
          // make the card resizable for demo
          overflow: 'auto',
          resize: 'horizontal',
          "&:hover": {
            background: (theme) =>
            `${theme.vars.palette.neutral.plainHoverBg}`
          } 
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 182 }}>
          <img
            src={member.community.image?.src}
            
            loading="lazy"
            alt=""
          />

        </AspectRatio>
        <CardContent>
          <Typography fontSize="xl" fontWeight="lg">
            { member.community.name }
          </Typography>
          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
            Member since { relativeDate(member.community.createdAt) }
          </Typography>
          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Members
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.members }</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Online
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.online }</Typography>
            </div>
            <div>
              <Typography level="body-sm" fontWeight="lg">
              Messages
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.messages }</Typography>
            </div>
          </Sheet>
         
        </CardContent>
      </Card>
    </Box>
  );
}