import * as React from 'react';
import { Button, DialogContent, DialogTitle, FormControl, FormLabel, Input, Modal, ModalDialog, Stack } from '@mui/joy';

type MyProps = {
  // using `interface` is also ok
  name: string;
  description?: string;
  onClose: React.EventHandler<React.FormEvent>;
};

type MyState = {
 
};

export default class CreateCommunityModal extends React.Component<MyProps, MyState>
{
  constructor(props: MyProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal = (e: React.FormEvent) => {
    console.log("closeModal handler in component hit", this.props.onClose);
    this.props.onClose(e);
  }
  

render() {
 

  return (
    <Modal open={true} onClose={this.closeModal}>
        <ModalDialog>
          <DialogTitle>Create new project</DialogTitle>
          <DialogContent>Fill in the information of the project.</DialogContent>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              this.closeModal(event);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input autoFocus required />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Input required />
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
  );
  }
}