import { Box, BoxProps, CssBaseline, CssVarsProvider } from "@mui/joy";
import React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import Footer from "../components/Footer";
import { useCommunityContext } from "../Context/CommunityContext";
import { CommunityProvider } from "../Context/CommunityProvider";
import { GlobalProvider } from "../Context/GlobalProvider";

export function AppLayout(props: BoxProps) {

return (
  <React.Fragment>

  <CssVarsProvider disableTransitionOnChange>
    <CssBaseline />

    <GlobalProvider>
      <Layout.Root>

        <Layout.Header>
          <Header />
        </Layout.Header>
            
        <Layout.Main>
          <Outlet />
        </Layout.Main>

        <Layout.Footer>
          <Footer/>
        </Layout.Footer>

      </Layout.Root>
    </GlobalProvider>
 
  </CssVarsProvider>
</React.Fragment>
)
};


