import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { Box, Card, Divider, Grid, List, Stack, Typography } from "@mui/joy";
import { BoxProps } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router';
import CommunityHomeCard from "../../components/CommunityHomeCard";
import CreateContentItemForm from "../../components/CreateContentItemForm";
import * as _ from 'lodash';
import { SET_ONLINE_STATUS_INTERVAL } from "../../Const";
import { CommunityProvider } from "../../Context/CommunityProvider";
import { useCommunityContext } from "../../Context/CommunityContext";
import { CContentItem } from "../../Model";
import MessageBubble from "../../components/MessageBubble";
import { useAuthContext } from "../../Context/AuthContext";
import MessagesPane from "../../components/MessagesPane";






// const CONTENT_ITEMS_SUBSCRIPTION = gql`
//   subscription OnContentItemAdded($community: ID!) {
//     contentItemAdded(community: $community) {
//       _id
//       createdAt
//       title
//       body
//       author {
//         _id
//         name
//         image {
//           src
//         }
//       }
//     }
//   }
// `;



export function Messages(props: BoxProps) {
  const { community } = useCommunityContext();
    
  return (
    
<div>
  <MessagesPane community={community}/>   
</div> 
  );
};