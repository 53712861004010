import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';

import CommunityTemplateCard from '../components/CommunityTemplateCard';
import { Box, Grid, Typography } from '@mui/joy';
import CreateCommunityModal from '../Modals/CreateCommunityModal';

export default function CreateCommunity() {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
  
<div style={{}}>
<Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    
    position:"absolute"
  }}>

<Grid container spacing={3} padding={2} style={{}} columns={{ sm: 2 }}>
  <Grid>
  
      <Typography level="h1">Create a new community</Typography>
      <Typography level="h3">What kind of community do you want to create?</Typography>
  
  </Grid>
  <Grid container padding={2} spacing={0} columns={{ xs: 1, sm: 2, md: 4 }}>

    
  {/* <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={2}
  > */}
  
        <CommunityTemplateCard name="Classroom" 
          description="Create a group for your class students so  you can easily communicate with them and share homework, messages and links" 
          description2="For teachers" 
          image=""
          onClick={() => setOpen(true)}
        />
        <CommunityTemplateCard name="Family & Friends" 
          description="Just share news, pictures and fun in your family or group of friends" 
          description2="Messages and Photos" 
          image=""
          onClick={() => setOpen(true)}
        />

        <CommunityTemplateCard name="Generic" 
          description="Some some people in a group" 
          description2="Messaging" 
          image=""
          onClick={() => setOpen(true)}
        />
        
        
    
  
    </Grid> 
    {open?(<CreateCommunityModal name="Create Community" onClose={() => { console.log("HIT ON page!"); setOpen(false)}} />):undefined}
  
  </Grid>
  
</Box>
  
</div>
 
  );
}