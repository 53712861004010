import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import AvatarWithStatus from './AvatarWithStatus';
import MessageInput from './MessageInput';
 
import { ChatProps, MessageProps } from '../types';
import MessageBubble from './MessageBubble';
import { CContentItem, CMember, MemberStatus } from '../Model';
import { AuthContext, useAuthContext } from '../Context/AuthContext';
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client';
import _ from 'lodash';
import { TimerOptions } from 'timers';
import { useCommunityContext } from '../Context/CommunityContext';
import { Avatar, Typography } from '@mui/material';
import { useGlobalContext } from '../Context/GlobalContext';
import MessagesPaneHeader from './MessagesPageHeader';

const STOP_TYPING_AFTER_IDLE_MS = 2000;

const CONTENT_ITEMS = gql`
  query contentItems($community: ID!) {
    contentItems(community: $community, max: 10) {
      _id 
      author {
        _id
        name
        image {
          src
        }
      }
      area     
      title
      body
      createdAt

    }
  }
`;

const CREATE_CONTENT_ITEM = gql`
  mutation createContentItem($community: ID!, $area: String!, $title: String, $body: String) {
    createContentItem (community: $community, area: $area, title: $title, body: $body) {
      _id 
      author {
        _id
        name
        image { 
          src
        }
      }
      area     
      title
      body
      createdAt
    }
  }
`;


const SET_TYPING = gql`
  mutation setTyping($community: ID!, $scope: String!, $other: ID, $typing: Boolean!) {
    setTyping (community: $community, scope: $scope, other: $other, typing: $typing)
  }
`;


const SUBSCRIBE_TYPING = gql`
  subscription onActionChanged($community: ID!, $scope: String!, $other: ID) {
    actionChanged(community: $community, scope: $scope, other: $other) {
      user
      name
      typing
    } 
  }
`;

const MARK_UPDATES = gql`
  mutation onMarkUpdates($community: ID!, $area: String!) {
    markUpdates(community: $community, area: $area)
  }
`;


const CONTENT_ITEMS_SUBSCRIPTION = gql`
  subscription OnContentItemAdded($community: ID!) {
    contentItemAdded(community: $community) {
      _id
      createdAt
      title
      body
      author {
        _id
        name
        image {
          src
        }
      }
    }
  }
`;

type TypingUser = { 
  user: string;
  name: string;
  typing: boolean;
}

type TypingUsers = {
  [id: string]: TypingUser
}


type MessagesPaneProps = {
  community: string;
  //contentItems: IContentItem[]
  member?: CMember;
};

export default function MessagesPane(props: MessagesPaneProps) {
  const { updates, setUpdates, userIsAway } = useGlobalContext();
  const { membership, getOnlineStatus  } = useCommunityContext()
  const memberId = membership._id;

  const { community } = props;
  
  const { auth } = useAuthContext();
  const { loading, error, data: contentItems } = useQuery( CONTENT_ITEMS, { variables: { community } } );
  const [ createContentItem, { data:sendData, loading:sendLoading, error:sendError }] = useMutation(CREATE_CONTENT_ITEM);
  const [ setTyping ] = useMutation(SET_TYPING, { variables: { community, scope: 'messages' } } );
  const [ markUpdatesAsSeen ] = useMutation(MARK_UPDATES, { variables: { community, area: 'messages' }})
  const [ isTypingNow, setIsTypingNow ] = React.useState<string>("");
  const [ chatMessages, setChatMessages] = React.useState<CContentItem[]>([]);
  const [ textAreaValue, setTextAreaValue] = React.useState('');

  const [ typingUsers, setTypingUsers ] = React.useState<TypingUsers>( {} );
  const [ commentAdded, setCommentAdded] = React.useState(0)

 
  useSubscription(CONTENT_ITEMS_SUBSCRIPTION, 
    { 
      variables: { community: community },
      onData: (subscriptionData: any) => {
          
        if (subscriptionData?.data?.data?.contentItemAdded) {
          const arr: CContentItem[] = [  subscriptionData?.data?.data?.contentItemAdded, ...chatMessages,];
          setChatMessages( arr );
          setCommentAdded( commentAdded+1 );
        }
      }
    }); 

  useSubscription(SUBSCRIBE_TYPING, {
    variables: { community, scope:'messages' },
    onData: (subscriptionData: any) => {
      
      if (subscriptionData?.data?.data?.actionChanged) {
        const typingUser: TypingUser = subscriptionData.data.data.actionChanged;
        if (typingUser && typingUser.user !== auth.user) {
          // update the hashtable
          if (typingUser.typing) {
            typingUsers[typingUser.user] = typingUser;
          } else {
            delete typingUsers[typingUser.user];
          }

          
          const arr = [];
          for (const typingUser of Object.values(typingUsers)) {
            if (typingUser.typing) {
              arr.push(typingUser.name);
            }
          }
      
          let affix = '';
          if (arr.length === 1) affix = ' is typing ...';
          if (arr.length > 1) affix = ' are typing ...';
      
          setIsTypingNow(arr.join(', ') + affix);
        }
        
      }
    }
  })

  const isTyping = React.useRef<boolean | null>(false);
  const lastTyped = React.useRef<number | null>(0);

  const typingCheckInterval: React.MutableRefObject<NodeJS.Timer | null> = React.useRef<NodeJS.Timer | null>(null);

  const endOfMessages = React.useRef<HTMLInputElement | null>(null);

  
  function isOnline(memberId:string): boolean {
    return true;
  }

 
  
  const scrollToBottom = () => {
    endOfMessages.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

 
  React.useEffect(() => {
    if (!loading && !error) {
      const items: CContentItem[] = _.orderBy(contentItems.contentItems, 'createdAt', 'desc');
      setChatMessages(items);
      setCommentAdded(commentAdded+1);
    }
  }, [contentItems, loading, error]);

  React.useEffect(() => {
    scrollToBottom();
    markUpdatesAsSeenIfNeeded();
  }, [commentAdded]);

 


  async function sendMessage( formData: any ) {
   
    stopTyping();
    const data = await createContentItem( { variables: { community, body: formData.body, area: "messages"}});
    const contentItem = data.data.createContentItem as CContentItem;
    console.log("new ContentItem is", contentItem);

    const arr: CContentItem[] = [ contentItem, ...chatMessages, ];
    setChatMessages( arr );
    setCommentAdded( commentAdded+1 );

    scrollToBottom();
    
  }

  React.useEffect( () => {
    typingCheckInterval.current = setInterval( () => {
      if (isTyping.current && lastTyped.current && lastTyped.current < (Date.now() - STOP_TYPING_AFTER_IDLE_MS)) {
        stopTyping();
      }
    }, 1000 ); 
    
  }, []);

  function handleTyping() {
    lastTyped.current = Date.now();
    if (!isTyping.current) {
      setTyping( { variables: { typing: true } } );
      markUpdatesAsSeenIfNeeded();
    }
    isTyping.current = true;
  }

  function stopTyping() {
    isTyping.current = false;
    setTyping( { variables: { typing: false } } );
    console.log("Stopped typing")
  }

  function markUpdatesAsSeenIfNeeded() {
    if (updates.messages && !userIsAway) {
      const { messages:_, ...rest } = updates; // cut out messages property
      setUpdates(rest);

      markUpdatesAsSeen();
    }
  }
  
  return (
    <Sheet
      sx={{
        width:'100vw',
        height: { xs: 'calc(100dvh - var(--Header-height)) - var(--Footer-height))', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        
      }}
    >
      {/* {props.member?<MessagesPaneHeader 
        member={props.member} 
        online={getOnlineStatus(props.member._id.toString())?.online}/>:undefined} */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Box sx={{height:'160px', width:'100%'}} >
          &nbsp;
          <div ref={endOfMessages}></div>
        </Box>
        <Box>
          <Typography fontSize="sm"> 
            {isTypingNow} &nbsp;
          </Typography>
        </Box>
        <Stack justifyContent="flex-end" sx={{flexDirection:'column-reverse' }}>
          {chatMessages.map((message: CContentItem, index: number) => {
            const isYou = (message.author._id == memberId);
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
                sx={{ marginBottom:1}}
              >
                {message.author._id !== memberId && (
                  <AvatarWithStatus
                    online={getOnlineStatus(message.author._id.toString())?.online}
                    image={message.author.image}
                  />
                )}
                <MessageBubble variant={isYou ? 'sent' : 'received'} isYou={isYou} { ...message } />
              </Stack>
            );
          })}
          
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onTyping={handleTyping}
        onFocus={markUpdatesAsSeenIfNeeded}
        onSubmit={sendMessage}
      />
    </Sheet>
  );
}