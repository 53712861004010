import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';

import Typography from '@mui/joy/Typography';
import { AspectRatio, Badge, Grid, ListDivider, ListItem, ListItemButton, ListItemDecorator, Sheet, Stack } from '@mui/joy';
import { fullDate, relativeDate, shortDate } from '../utils';
import { MemberStatus, CUser, CMember } from '../Model';

import Chip from '@mui/joy/Chip';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import SvgIcon from '@mui/joy/SvgIcon';
import moment from 'moment';
import { useCommunityContext } from '../Context/CommunityContext';
import { Link, useLocation } from 'react-router-dom';

export default function CommunityMemberCard( { member, community, online, isYou }: { member:CMember, community:string, online?:boolean, isYou?:boolean } ) {
  const isAdmin = member.role == 'admin';
  const link = isYou?`/c/${community}/profile` : `/c/${community}/members/${member._id.toString()}`;
  return (
<Grid>
  <Card
    orientation="horizontal"
    variant="outlined"
    sx={{
      width: '350px',
      p: 2,
      s: 2,
      margin: 1,
      '&:hover': { boxShadow: 'md', bgcolor: 'background.level1'},
      cursor: 'pointer',    
    }}
  >
   
    <CardContent>
      <Link to={link} style={{textDecoration: 'none'}}>
        <Stack direction="column" spacing={2} >
          <Box  
            sx={{
              position: 'relative',
              // overflow: { xs: 'auto', sm: 'initial' },
            }}
          >
              <Stack direction="row" spacing={1}  >
                <Box>
                  <Badge
                      color={online ? 'success' : 'neutral'}
                      variant={online ? 'solid' : 'soft'}
                      size="lg"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeInset="10px 10px"
                      invisible={!online}
                    >
                      
                    <img
                      src={member.image?.src}
                      
                      loading="lazy"
                      alt=""
                      width="80"
                      height="80"
                      style={{borderRadius: 150}}
                      referrerPolicy="no-referrer"
                    />
                  </Badge>
                </Box>
                <Stack direction="row" justifyContent='begin'>
                  <Stack direction="column" >
                    <Typography fontSize="lg" fontWeight="lg" >
                      {member.name} 
                    </Typography>
                    {/* {online?<Typography fontSize="xs" fontWeight="sm" textColor="text.tertiary" >
                      online now
                      </Typography>:undefined} */}

                    {member.about?<Typography fontSize="xs" fontWeight="sm" textColor="text.tertiary" >
                      {member.about}
                    </Typography>:undefined}
                   
                  </Stack>
                  
                  {isYou?(
                    <Chip sx={{ height: '1em', position:'absolute', right:1, top: 1 }}
                      color="primary"
                      onClick={function(){}}
                      size="sm"
                      variant="outlined"
                    >You</Chip>) : "" }
                  {isAdmin?(
                    <Chip sx={{ height: '1em', position:'absolute', right:1, bottom: 1 }}
                      color="primary"
                      onClick={function(){}}
                      size="sm"
                      variant="solid"
                    >admin</Chip>) : "" }
                    {/* <Typography level="body-sm" fontWeight="md" textColor="text.tertiary">
                      {member.user._id}
                    </Typography> */}
                  
                  </Stack>
              
                </Stack>
              
          </Box>
        </Stack> 
      </Link>
    </CardContent>
  </Card>
</Grid>
  );
}
