import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { jwtDecode } from "jwt-decode";
import { AuthDetails } from '../Model/clientOnlyModel';
import { CMember } from '../Model';

export const LOCAL_STORAGE_TOKEN_NAME = 'token';

const AUTH_GOOGLE = gql`
  mutation authGoogle($credentials: String!) {
    authGoogle(credentials: $credentials) {
      token
      code
    }
  }
`;

const MY_MEMBERSHIPS = gql`
  query myMemberships {
    myMemberships {
      _id
      role
      name
      image {
        src
      }
      joinedAt
      community {
        _id
        name
        createdAt
        stats {
          members
          online
          messages
        }
        image {
          src
        }
      },
      updates {
        home 
        messages
      }
      
    }
  }
`;

type Props = {
  children?: ReactNode;
}

type IAuthContext = {
  authenticated: boolean;
  auth: AuthDetails;
  memberships: CMember[];
  authGoogle: (credentials: string) => any
  setAuthenticated: (newState: boolean) => void
  setAuth: (newAuth: AuthDetails) => void
}

const initialValue = {
  authenticated: false,
  auth: {},
  memberships: [],
  authGoogle: (dummy:string) => {},
  setAuthenticated: () => {},
  setAuth: () => {}
}

let memberships: CMember[] = [];

const AuthContext = createContext<IAuthContext>(initialValue)

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

const AuthProvider = ({children}: Props) => {

  const verifyToken = () => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
    if (token) {
      // there is a -possibly old- token, let's see if we can use it.
      try {
        const props:any = jwtDecode(token);
        
        if (props && props.exp * 1000 >= Date.now()) {
          // still valid
          return {
            _id: props._id,
            name: props.name,
            email: props.email,
            image: props.image,
            user: props.user,
          };
        }
      } catch (e) {
        console.error("invalid token"); 
      }       
    }
    return undefined;
  }

  const authFromToken = verifyToken();

  //Initializing the auth state
  const [ authenticated, setAuthenticated ] = useState(!!authFromToken);
  const [ auth, setAuth ] = useState(authFromToken || {})
  const { loading: membershipsLoading, data: myMembershipsData } = useQuery(MY_MEMBERSHIPS, { skip: !authenticated });

  const [func, { data, loading, error }] = useMutation(AUTH_GOOGLE);

  const authGoogle = async (credentials: string) =>  {
    const res = await func( { variables: { credentials } } );
    const jwt = res.data.authGoogle.token;

    // store token in localstorage
    localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, jwt);
    setAuth({ ...verifyToken() } )
    setAuthenticated(true);


    return res.data.authGoogle;
  }

  if (!membershipsLoading && myMembershipsData?.myMemberships) {
    memberships = myMembershipsData.myMemberships;
  };

  useEffect( () => {

  }, [authenticated])

  return (
    <AuthContext.Provider value={{authenticated, authGoogle, auth, setAuth, setAuthenticated, memberships}}>
      {membershipsLoading?undefined:children}
    </AuthContext.Provider>
  )
}

export {  AuthContext, AuthProvider }