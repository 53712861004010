
type Props = {
  key: string,
  show: boolean,
  children: React.ReactNode
};


export const AnimatedListItem = ({children, show}: { children: React.ReactNode, show?: boolean}) => {
  return ( 
    <div style={{
      transition: 'all 0.3s',
      opacity: show?1:0,
      height: show?'130px':'0px',
      visibility: show?'visible':'hidden',
    }}>
      {children}
    </div>
  )
};