import { gql, useQuery } from "@apollo/client";
import { Grid, Input, List, Sheet, Stack, Switch, Typography } from "@mui/joy";
import { Box, BoxProps } from "@mui/joy";
import * as _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
import { useCommunityContext } from "../../Context/CommunityContext";
import CommunityMemberCard from "../../components/CommunityMemberCard";
import { CInvite, CMember } from "../../Model";
import InviteMemberForm from "../../components/InviteMemberForm";
import InviteMemberCard from "../../components/InviteMemberCard";
import { CardMembership, CheckCircleOutlined, SearchOffRounded } from "@mui/icons-material";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { useEffect, useState } from "react";
import React from "react";
import { AnimatedListItem } from "../../components/AnimatedListItem";
import { useAuthContext } from "../../Context/AuthContext";
import InviteMoreCard from "../../components/InviteMoreCard";
import Divider from '@mui/joy/Divider';

const COMMUNITY_MEMBERS_QUERY = gql`
  query OnCommunityMembers($community: ID!) {
    communityMembers(community: $community) {
      _id
      joinedAt
      role
      name
      image {
        _id
        src
      }
      about
    }
  }  
`;

const INVITES_QUERY = gql`
  query OnInvites($community: ID!) {
    invites(community: $community) {
      _id
      name
      email
      status
    }
  }
`;
export function Members(props: BoxProps) {
  const { auth } = useAuthContext();
  const { community, getOnlineStatus, membership, isAdmin} = useCommunityContext();
  const { loading: loadingData, data } = useQuery(COMMUNITY_MEMBERS_QUERY, { fetchPolicy: 'network-only', variables: { community } } );
  const { loading: loadingInvites, data:dataInvites } = useQuery(INVITES_QUERY, { variables: { community } } );
  
  const [ members, setMembers ] = useState<CMember[]>([]);
  const [ filteredMembers, setFilteredMembers ] = useState<CMember[]>([]);
  const [ onlineFilteredMembers, setOnlineFilteredMembers ] = useState<CMember[]>([]);
  const [ invites, setInvites ] = useState<CInvite[]>([]);

  const [ search, setSearch ] = useState<string>("");
  const [ onlineOnly, setOnlineOnly ] = useState<boolean>(false);

  const memberId = membership._id;

  useEffect( () => {

    if (!loadingData && data && data.communityMembers) {
      setMembers(data.communityMembers);
    }
  }, [ data ]);

  useEffect( () => {
    if (!loadingInvites && dataInvites && dataInvites.invites) {
        setInvites(dataInvites.invites);
      }
  }, [ dataInvites ]);


  return (
    <Sheet
      sx={{
        width:'100vw',
        height: { xs: 'calc(100dvh - var(--Header-height) - var(--Footer-height))', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        
        
      }}
    >

    <Stack direction="row" width="100%" height="44px" justifyContent="begin"
        sx={{
          p:0.5,
          pl: 1, pr: 1,
          position:'fixed',
          backgroundColor: 'background.level1',
        }}>

        <Typography component="label" endDecorator={
          <Switch color={ onlineOnly?'success':'neutral' } 
            variant='solid' 
            size='lg' 
            checked={onlineOnly} 
            onChange={ event => setOnlineOnly(event.target.checked) }
            sx={{ 
              //ml: 1,
            }} />
        }>
          Online
        </Typography>

       
          <Input
          size="sm"
          endDecorator={<SearchRounded />}
          slots={{ input: undefined }}
          slotProps={{ input: { placeholder: 'Search members', type: 'search' } }}
          value={search}
          onChange={event => setSearch(event.target.value)}
          sx={{
            mt: 0.75,
            position: 'absolute',
            right: '2em',
            bgcolor: 'background.level1',
            '--Input-minHeight': '32px',
            '--Input-radius': '6px',
          }}
      />
      
    </Stack>

    <Stack direction="column" width="100%" sx={{ marginTop:'58px'}}>
      


      
  {loadingData?undefined:
  <Grid container columns={{ xs: 12 }}>
        {!members || members.length === 0?(
          undefined
        ):members.map((member: CMember) => {
          const onlineStatus = !!(getOnlineStatus(member._id.toString())?.online);
          const show = (onlineOnly && onlineStatus || !onlineOnly) && (member.name.toLowerCase().includes(search.toLowerCase().trim()) || search ==='')
          return (
          <AnimatedListItem key={member._id.toString()} show={show}>
            <CommunityMemberCard 
                member={member}
                community={community}
                online={onlineStatus}
                isYou={member._id == memberId }
            ></CommunityMemberCard>

          </AnimatedListItem>)
          }
      
        )}
  
        {isAdmin?<InviteMoreCard />:undefined}     
  
      </Grid>}
    </Stack>
  </Sheet>
); }