import { gql, useMutation, useQuery, useLazyQuery, useSubscription } from "@apollo/client";
import { Component, ReactNode, useEffect, useReducer, useState } from "react";
import { useNavigation, useParams } from "react-router";
import { SET_ONLINE_STATUS_INTERVAL } from "../Const";
import { CMember, MemberStatus } from "../Model";
//import { Membership } from "../Model/clientOnlyModel";
import CreateCommunity from "../Pages/CreateCommunity";
import CommunityContext from "./CommunityContext";
import { useGlobalContext } from "./GlobalContext";

type Props = {
  children: React.ReactNode
};

type onlineUsersHashtable = {
  [memberId:string]: MemberStatus
}

const ONLINE_MEMBERS_QUERY = gql`
  query queryOnlineMembers($community: ID!) {
    onlineMembers(community: $community) {
      member
      online
    }

    myMembership(community: $community) {
      _id
      role
      community {
        _id
        name
        image {
          _id
          src
        }
      }

      name
      image {
        _id
        src
      }
      about
      updates {
        home
        messages     
      }
    }
  }
`;


// const ONLINE_MEMBERS_CHANGES = gql`
//   subscription OnOnlineMemberChanged($community: ID!) {
//     onlineMemberChanged(community: $community) {
//       member
//       online
//     } 
//   }
// `;

const COMMUNITY_CHANGES = gql`
  subscription onCommunityChanged($community: ID!) {
    communityUpdates(community: $community) {
      source

      member
      online

      area
      
    }
  }
`;


const SET_ONLINE_STATUS_MUTATION = gql`
  mutation setOnlineStatus  ($community: ID!, $online: Boolean) {
    setOnlineStatus (community: $community, online: $online)
  }
`;



export const CommunityProvider:React.FC<{children: React.ReactNode}> = ({children}) => {
 // let [ community, setCommunity ] = useState<string>("");

  const { community, updates, setUpdates } = useGlobalContext();
  if (!community) throw new Error("CommunityProvider called where no community selected via the route");
  const [ membership, setMembership ] = useState<CMember>();
  const [ onlineMembers, setOnlineMembers ] = useState<onlineUsersHashtable>( {} );
  const [ mutateFunction ] = useMutation(SET_ONLINE_STATUS_MUTATION, { variables: { community } } );
  const [ isAdmin, setIsAdmin ] = useState<boolean>(false);

  useSubscription(COMMUNITY_CHANGES, {
      variables: { community },
      onData: (subscriptionData: any) => {
        console.log("Online users SUB", subscriptionData);
        const myMembershipId = membership?._id.toString();

        if (subscriptionData?.data?.data?.communityUpdates) {
          const communityUpdates = subscriptionData?.data?.data?.communityUpdates;
          if (communityUpdates.member && communityUpdates.member !== myMembershipId) {
            onlineMembers[communityUpdates.member] = { member: communityUpdates.member, online: !!communityUpdates.online };
          } 

          if (communityUpdates.area && communityUpdates.source !== myMembershipId) {
            const counters:any = { ...updates };
            counters[communityUpdates.area] = counters[communityUpdates.area] ? counters[communityUpdates.area] +1 : 1;
            setUpdates(counters);
          }
        }
      }
    })
  
  useEffect( () => {   
      //console.log("Entering CommunityProvider", community);

      var keepAliveTimeout:NodeJS.Timer;

      function setIt() {
        keepAliveTimeout = setTimeout( async () => {
          // console.log("PRE keepAlive hit");
          await mutateFunction( { variables: { online: true } } );
          setIt();

        }, SET_ONLINE_STATUS_INTERVAL );
  
      }

      mutateFunction( { variables: { online: true } } );
      setIt();

      // we initiate a keep alive timer to share our online status with others
      //
      // eslint-disable-next-line react-hooks/exhaustive-deps
      
      return () => {
        // when we leave this community, we unsubscribe again (automatic by react), tell the server we're offline and stop our keep-alive interval.
        //console.log("cleaning up", community);
        clearInterval(keepAliveTimeout);
        mutateFunction( { variables: { online: false } }  );
      }
    }
    ,
    [community]
  );

  const { loading, data } = useQuery(ONLINE_MEMBERS_QUERY, { fetchPolicy: 'network-only', variables: { community } } );

  useEffect( () => {
    if (!loading && data) {

      
      // myMembership returns
      if (data && data.myMembership?._id) {
        console.log("my membership received", data.myMembership)
        setMembership(data.myMembership);
        setIsAdmin(data.myMembership.role == 'admin');
        setUpdates(data.myMembership.updates);
      }

      const online: onlineUsersHashtable = {}, action: onlineUsersHashtable = {};
      const myMemberId = data.myMembership._id;

      // we load the onlineUsers in our current community.
      if (data && data.onlineMembers) {
        for (const member of data.onlineMembers as MemberStatus[]) {
          if (member.member === membership?._id.toString()) continue; // skip for ourselves.
          online[member.member] = member;
        }
        // we ourselves definitely online
        online[myMemberId] = { online: true, member: myMemberId }
        setOnlineMembers(online);
       
        //console.log("initial online states received", data.onlineMembers);
      }

      
    }
  }, [community, data]);

    
  //
  // functions we pass on to our consumers to use (and should use if possible)
  //

    const getOnlineStatus = (memberId: string) => {
      //console.log("checking online status in hashtable", memberId);
      return onlineMembers[memberId];
    }

    const changeOnlineStatus = async ( { online }: { online: boolean } ) => {
      console.log('changeOnlineStatus call from consumer of CommunityProvider', { online })
      await mutateFunction( { variables: { online } } );
    }
    
  return ( 
    <div>
    { community && membership ?
      (<CommunityContext.Provider value={{ community, isAdmin, membership, onlineMembers, getOnlineStatus, changeOnlineStatus }}>
        {!loading && data?children:undefined}
      </CommunityContext.Provider>) : undefined }
    </div>
  );
};