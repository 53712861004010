import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { AspectRatio, Box, Card, CardContent, Divider, Grid, IconButton, Input, List, Sheet, Stack, Typography } from "@mui/joy";
import { BoxProps } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { useParams } from 'react-router';
import CommunityHomeCard from "../../components/CommunityHomeCard";
import CreateContentItemForm from "../../components/CreateContentItemForm";
import * as _ from 'lodash';
import { SET_ONLINE_STATUS_INTERVAL } from "../../Const";
import { CommunityProvider } from "../../Context/CommunityProvider";
import { useCommunityContext } from "../../Context/CommunityContext";
import { CContentItem } from "../../Model";
import { useNavigate } from "react-router-dom";
import { relativeDate } from "../../utils";
import { UPLOAD_URL } from "../../_Constants/Generic";
import EditRoundedIcon from '@mui/icons-material/EditRounded';


const SET_COMMUNITY_IMAGE = gql`
  mutation setCommunityImage($community: ID!, $image: String!) {
    setCommunityImage (community: $community, image: $image) {
      _id
      image {
        _id
        src
      }
      name
    }
  }

`;

export function Home(props: BoxProps) {
  const { community, getOnlineStatus, membership: member, onlineMembers, isAdmin } = useCommunityContext();
  const [ processing, setProcessing ] = useState<boolean>(false);

  const [ setCommunityImage ] = useMutation(SET_COMMUNITY_IMAGE, { variables: { community } } );
  // const navigate = useNavigate();
  // useEffect( () => {
  //   navigate(`/c/${community}/members`);
  // });

  console.log("comm image", member.community)

  const onUploadFrontImage = async (e:any) => {
    const file = e.currentTarget.files[0];
    const token = localStorage.getItem('token');

    setProcessing(true);
    
    const formData = new FormData();
    formData.append("file", file);
    try {
    
      
      const result = await fetch(UPLOAD_URL, {
        method: "POST",
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : ""
        }
      });

      const data = await result.json();

      if (data.images && data.images.length>0) {
        // upload succeeded
        const changedImage = data.images[0];
       
        await setCommunityImage( { variables: { image: changedImage._id} });
        
        
        return;
      } else {
        alert("Error uploading image.");
      }
    } catch (e) {
      console.error("Upload error", e);
    } finally {
      setProcessing(false);
    }
  }

  
  return (
    
<div>

 <Box
      sx={{
        width: '100vw',
        
        overflow: { xs: 'auto' },
        
      }}
    >
   
      <Card
        orientation="horizontal"
        sx={{
       
          flexWrap: 'wrap',
         
         
        }}
      >
        <AspectRatio flex ratio="1" maxHeight={182} sx={{ minWidth: 250 }}>
          <img
            src={member.community.image?.src}
            
            loading="lazy"
            alt=""
          />

        </AspectRatio>
        {isAdmin? (
        processing?undefined:
                <IconButton
                  component="label"
                  aria-label="upload new picture"
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  sx={{
                    bgcolor: 'background.body',
                    position: 'absolute',
                    zIndex: 2,
                    borderRadius: '50%',
                    right: 5,
                    top: 5,
                    boxShadow: 'sm',
                  }}
                >
                  <EditRoundedIcon />
                  <Input
                  
                  sx={{display:"none"}}
                  type="file"
                  hidden
                  onChange={onUploadFrontImage}
                  name="file"
                />
              </IconButton>) : undefined}
        <CardContent>
          <Typography fontSize="xl" fontWeight="lg">
            { member.community.name }
          </Typography>
          <Typography level="body-sm" fontWeight="lg" textColor="text.tertiary">
            
          </Typography>

          <Sheet
            sx={{
              bgcolor: 'background.level1',
              borderRadius: 'sm',
              p: 1.5,
              my: 1.5,
              display: 'flex',
              gap: 2,
              '& > div': { flex: 1 },
            }}
          >

            <Typography fontSize="md">
              Announcements
            </Typography>
            <Typography fontSize="sm">
              Can be placed here or any interactive component
            </Typography>

          

            {/* <div>
              <Typography level="body-xs" fontWeight="lg">
                Members
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.members }</Typography>
            </div>
            <div>
              <Typography level="body-xs" fontWeight="lg">
                Online
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.online }</Typography>
            </div>
            <div>
              <Typography level="body-sm" fontWeight="lg">
              Messages
              </Typography>
              <Typography fontWeight="lg">{ member.community.stats?.messages }</Typography>
            </div> */}
          </Sheet>
         
        </CardContent>
      </Card>
    </Box>
 
</div> 
);
}