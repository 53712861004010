import { useNavigate, useNavigation } from "react-router";
import { useContext } from 'react';
import { AuthContext } from "../Context/AuthContext";
import { Badge, Box, GlobalStyles } from "@mui/joy";

import * as React from 'react';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import MessageRounded from '@mui/icons-material/MessageRounded';
import People from '@mui/icons-material/People';
import Person from '@mui/icons-material/Person';
import { useGlobalContext } from "../Context/GlobalContext";
import { useCommunityContext } from "../Context/CommunityContext";

export default function Footer() {
 
  const navigate = useNavigate();
  const { community, updates } = useGlobalContext(); 
  
  const path = window.location.pathname;

  let currentTab = `${path?.replaceAll(`/c/${community}`,"")}`;
  //`/${path?.replaceAll(`/c/${community}`,"").split('/')[1]}`;
  const jumpTo = ( event: any, value: any) => {
    let path = `/c/${community}${value}`;
    navigate(path);
  }

  return (
   
      <Tabs
        size="sm"
        aria-label="Bottom Navigation"
        onChange={jumpTo}
        value={currentTab}
        sx={(theme) => ({
          p: 1,
          borderRadius: 16,
          maxWidth: 400,
          mx: 'auto',
          boxShadow: theme.shadow.sm,
          
          [`& .${tabClasses.root}`]: {
            py: 1,
            flex: 1,
            transition: '0.3s',
            fontWeight: 'sm',
            fontSize: 'sm',
            [`&:not(.${tabClasses.selected}):not(:hover)`]: {
              opacity: 0.7,
            },
          },
        })}
      >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Footer-height': '52px',
          },
        })}
      />
        <TabList
          variant="plain"
          size="sm"
          disableUnderline
          sx={{ borderRadius: 'lg', p: 0 }}
         
        >
          <Tab
            disableIndicator
            orientation="vertical"
            value="/"
          >
            <ListItemDecorator>
              <HomeRoundedIcon />
            </ListItemDecorator>
            Home
          </Tab>
          
          <Badge badgeContent={updates.messages} invisible={!updates.messages} size="sm" badgeInset="10%">
          <Tab
            disableIndicator
            orientation="vertical"
            value="/messages"
          >
            
            <ListItemDecorator>
              <MessageRounded />
            </ListItemDecorator>
            
             Messages
             
          </Tab>
          </Badge>
          
          <Tab
            disableIndicator
            orientation="vertical"
            value="/members"
          >
            <ListItemDecorator>
              <People />
            </ListItemDecorator>
            Members
          </Tab>
          <Tab
            disableIndicator
            orientation="vertical"
            value="/profile"
            
          >
            <ListItemDecorator>
              <Person />
            </ListItemDecorator>
            Profile
          </Tab>
        </TabList>
      </Tabs>
  );
}

