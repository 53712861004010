import * as React from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import GoogleIcon from '../../components/GoogleIcon';
import { Avatar, ButtonGroup, CardActions, CardContent, Grid, Step, Stepper, SvgIcon } from '@mui/joy';
import { UserMedium } from '../../components/UserMedium';
import { alignProperty } from '@mui/material/styles/cssUtils';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import Chip from '@mui/joy/Chip';

import Card from '@mui/joy/Card';
import CardOverflow from '@mui/joy/CardOverflow';
import { CInvite } from '../../Model';
import { useAuthContext } from '../../Context/AuthContext';
import { AuthDetails } from '../../Model/clientOnlyModel';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleCredentialResponse, googleLogout } from '@react-oauth/google';

const LOAD_INVITE = gql`
  query Invite($invite: ID!, $inviteCode: String!) {
    invite(invite:$invite, inviteCode: $inviteCode) {
      _id
      sender {
        name
        image {
          src
        }
      }
      name
      email
      community {
        _id
        name
      }
      createdAt,
      message
      rules {
        maxUsers
      }
    }
  }
`;

const ACCEPT_INVITE = gql`
  mutation onAcceptInvite($invite: ID!, $inviteCode: String!) {
    acceptInvite(invite: $invite, inviteCode: $inviteCode)
  }
`;

const IGNORE_INVITE = gql`
  mutation onIgnoreInvite($invite: ID!, $inviteCode: String!) {
    ignoreInvite(invite: $invite, inviteCode: $inviteCode)
  }
`;

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export function Invite() {
  const { auth, authenticated, authGoogle } = useAuthContext();
  const params = useParams();
  const [ searchParams ] = useSearchParams();
  const { invite } = params;
  const inviteCode = searchParams.get('code');
  const { loading, error, data } = useQuery(LOAD_INVITE, { variables: { invite, inviteCode }  });
  const navigate = useNavigate();

  let inviteObj: CInvite | undefined;
  if (!loading && data) {
    inviteObj = data.invite;
  }

  const [ acceptInvite ] = useMutation(ACCEPT_INVITE, { variables: { invite, inviteCode } } )
  const [ ignoreInvite ] = useMutation(IGNORE_INVITE, { variables: { invite, inviteCode } } )
  
  const [ step, setStep ] = React.useState<number>(1);
  
  async function onAcceptInvite() {
    // todo: we need to know to what user:
    //
    // - the current logged in user (first case: this.)
    // - sign in as someone else
    // - register as another user first

    setStep(2);
  }

  async function onConfirm() {
    const memberId = await acceptInvite( );
    console.log("Result:", { memberId });

    const community = inviteObj?.community._id;
    navigate(`/c/${community}/messages`)
  }

  async function onBack() {
    setStep(1);
  }

  async function onIgnoreInvite() {
    await ignoreInvite( );
    navigate('/')
  }


  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      
      position:"absolute"
    }}>


    {(loading || !inviteObj)?undefined:(
      
      step === 1?Step1(inviteObj, onAcceptInvite, onIgnoreInvite) :(
        authenticated?Step2_loggedIn(auth, inviteObj, onConfirm, onBack) : 
        Step2_anonymous(inviteObj, authGoogle, onConfirm, onBack)
      )
      )}

      
  </Box>
  

  );
}


function Step1(invite: CInvite, onAcceptInvite: () => void, onIgnoreInvite: () => void) {
  return (
    <Card
      sx={{
        width: 320,
        marginTop: '50%',
        left: '50%',
        maxWidth: '100%',
        boxShadow: 'lg',
      }}
    >
      <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
     
        <Avatar src={invite?.sender?.image?.src} sx={{ '--Avatar-size': '4rem' }} />
        <Chip
          size="sm"
          variant="soft"
          color="primary"
          sx={{
            mt: -1,
            mb: 1,
            border: '3px solid',
            borderColor: 'background.surface',
          }}
        >
          {invite?.sender?.name}
        </Chip>
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          invites you to join
        </Typography>

        <Typography level="title-lg">{invite?.community.name}</Typography>
      
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          {invite?.message?invite?.message : ""}
          
        </Typography>


        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
            '& > button': { borderRadius: '2rem' },
          }}
        >
         <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          Do you want to accept the invite?
          
        </Typography>
        </Box>
      </CardContent>
      <CardOverflow sx={{ bgcolor: 'background.level1' }}>
        <CardActions buttonFlex="1">
          <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
            <Button variant="solid" type="primary" onClick={onAcceptInvite}>Yes I'm in!</Button>
            <Button onClick={onIgnoreInvite}>Ignore</Button>
          </ButtonGroup>
        </CardActions>
      </CardOverflow>
    </Card>
  )
}

function Step2_loggedIn(auth: AuthDetails, invite: CInvite, onConfirm: () => void, onBack: () => void) {
  return (
    <Card
      sx={{
        width: 320,
        marginTop: '50%',
        left: '50%',
        maxWidth: '100%',
        boxShadow: 'lg',
      }}
    >
      <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
     
       
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          almost there
        </Typography>
        <br/>
        <Typography level="title-lg">Shall we add this community<br/>to your current account?</Typography>
        <br/>
        <Avatar src={auth.image?.src} />
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          
          {auth.name} 
        </Typography>


        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
            '& > button': { borderRadius: '2rem' },
          }}
        >
     
        </Box>
      </CardContent>
      <CardOverflow sx={{ bgcolor: 'background.level1' }}>
        <CardActions buttonFlex="1">
          <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
            <Button variant="solid" type="primary" onClick={onConfirm}>Yes add it!</Button>
            <Button onClick={onBack}>no</Button>
          </ButtonGroup>
        </CardActions>
      </CardOverflow>
    </Card>
  )
}

function Step2_anonymous(invite: CInvite, authGoogle: (credentials:string) => any, onConfirm: () => void, onBack: () => void) {



  const googleLoginSuccess = async (credentialResponse: GoogleCredentialResponse) => {
    const res = await authGoogle( credentialResponse.credential! );
    
    // login succesful. There is a lot of reloading to do
    console.log("authentication successful!", res);
    
    // now, accept invite
    onConfirm();
  };

  return (
    <Card
      sx={{
        width: 320,
        marginTop: '50%',
        left: '50%',
        maxWidth: '100%',
        boxShadow: 'lg',
      }}
    >
      <CardContent sx={{ alignItems: 'center', textAlign: 'center' }}>
     
       
        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          welcome to Clupje!
        </Typography>
        <br/>
        <Typography level="title-lg">login or register</Typography>

        <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>

        </Typography>
        <br/>
        <GoogleLogin
          onSuccess={credentialResponse => {
            googleLoginSuccess(credentialResponse);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          //useOneTap
          // auto_select
        />

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            mt: 2,
            '& > button': { borderRadius: '2rem' },
          }}
        >
         <Typography level="body-sm" sx={{ maxWidth: '24ch' }}>
          please login with your personal account to join<br/><br/><b>{invite.community.name}</b>
          
        </Typography>
        </Box>
      </CardContent>
      <CardOverflow sx={{ bgcolor: 'background.level1' }}>
        <CardActions buttonFlex="1">
          <ButtonGroup variant="outlined" sx={{ bgcolor: 'background.surface' }}>
            
            <Button onClick={onBack}>back</Button>
          </ButtonGroup>
        </CardActions>
      </CardOverflow>
    </Card>
  )
}