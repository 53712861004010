import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { Card, CardContent, Grid, ListDivider, ListItem, ListItemButton, ListItemDecorator, Stack, useColorScheme } from '@mui/joy';
import { useCommunityContext } from '../Context/CommunityContext';
import { Link } from 'react-router-dom';
import { DARK_STRIPED_BACKGROUND, LIGHT_STRIPED_BACKGROUND } from '../_Constants/Generic';
import { Icon } from '@mui/material';
import { PlusOneRounded } from '@mui/icons-material';


export default function InviteMoreCard() {

  const { community } = useCommunityContext();
  const link = `/c/${community}/members/invites`;
  const { mode } = useColorScheme();
return (
<Grid>
  <Card
    orientation="horizontal"
    variant="outlined"
    sx={{
      width: '350px',
      p: 2,
      s: 2,
      margin: 1,
      '&:hover': { boxShadow: 'md', bgcolor: 'background.level1'},
      cursor: 'pointer', 
      
      // stripes
      // height: '200px',
      // width: '200px',
      background: mode === 'dark'?DARK_STRIPED_BACKGROUND:LIGHT_STRIPED_BACKGROUND,
      
    }}
  >
   
    <CardContent>
      <Link to={link} style={{textDecoration: 'none'}}>
        <Stack direction="column" spacing={2} >
          <Box  
            sx={{
              position: 'relative',
              // overflow: { xs: 'auto', sm: 'initial' },
            }}
          >
            <Stack direction="row" spacing={1}  >
              <Box>
                <div style={{borderRadius: 150, height: 50, width: 80}}>
                  <PlusOneRounded sx={{height:'100%', width:'80px'}}/>
                </div>
              </Box>
              <Stack direction="row" justifyContent='begin'>
                <Stack direction="column" >
                  <Typography fontSize="lg" fontWeight="lg" >
                    Invite more...
                  </Typography>

                  <Typography fontSize="xs" fontWeight="sm" textColor="text.tertiary" >
                    You can invite more people right here
                  </Typography>
                  
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack> 
      </Link>
    </CardContent>
  </Card>
</Grid>
)};