
import { Box, Button, Card, CardActions, CardOverflow, Divider, FormControl, FormHelperText, Select, Option, Stack, Textarea, Typography, Input, ListItem, ListItemButton, ListItemDecorator, Badge, Avatar, ListDivider, FormLabel, Modal, DialogContent, DialogTitle, ModalDialog } from "@mui/joy";
import React, { useContext, useEffect, useState } from "react";
import { gql, useMutation } from '@apollo/client';
import { useParams } from "react-router";
import CommunityContext, { useCommunityContext } from "../Context/CommunityContext";
import { debounce, last, throttle } from "lodash";
import { AuthContext } from "../Context/AuthContext";
import { Add } from "@mui/icons-material";

const TYPING_INDICATOR_DEBOUNCE_TIME = 2500;




const InviteMemberForm = ( { onInvite }: { onInvite: ( { email, name, message }: { email: string, name: string, message?: string}) => void }) => {
  const { community, membership } = useCommunityContext();
  const [ open, setOpen ] = useState<boolean>(false);

  
  const sendInvite = async (event: any) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());
   
    onInvite({ email: formJson.email.toString().trim(), name: formJson.name.toString().trim(), message: formJson.message.toString().trim() } );

  }

    return (
<React.Fragment>
      <Button
        variant="outlined"
        color="neutral"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Invite
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Who do you want to invite?</DialogTitle>
          <DialogContent>Fill in the email address and name of the person you want to invite</DialogContent>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              sendInvite(event);

              event.preventDefault();
              setOpen(false);
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input autoFocus required type="email" name="email"  />
              </FormControl>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input required name="name" />
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Input name="message" />
              </FormControl>
              <Button type="submit">Invite</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>

      //   <React.Fragment key="InviteForm">
        
         
      //     <form onSubmit={(event) => { onClick(event) }}>
      //       <Box sx={{ pl: 2, width: '100%' }}>
      //         <Box
      //           sx={{
      //             display: 'flex',
      //             justifyContent: 'space-between',
      //             mb: 0.5,
      //           }}
      //         >
      //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
      //             <FormControl>
      //               <FormLabel>Email</FormLabel>
      //               <Input name="email" type="email" autoFocus required />
      //             </FormControl>
                  
      //           </Box>
      //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, margin:1 }}>
      //             <FormControl>
      //               <FormLabel>Name</FormLabel>
      //               <Input name="name"/>
      //             </FormControl>
                    
      //           </Box>
      //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, margin:1 }}>
      //             <FormControl>
      //               <FormLabel>Message</FormLabel>
      //               <Input name="message" type="text" />
      //             </FormControl>
                    
      //           </Box>
      //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, margin:1 }}>
      //             <FormControl>
      //               <FormLabel>&nbsp;</FormLabel>
      //               <Button type="submit" size="md" variant="solid">
      //                 Invite!
      //               </Button>
      //             </FormControl>  
      //           </Box>
      //         </Box>
      //       </Box>
      //     </form>
      
  
      //   <ListDivider sx={{ m: 0 }} />
      // </React.Fragment>


      // <Card>
      //     <form onSubmit={(event) => { onClick(event) }}>
         
        
      //   </Card>
     
    )
  }


export default InviteMemberForm;