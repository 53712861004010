import { createContext, useContext } from "react";
import { CMember, CUpdates, MemberStatus } from "../Model";


interface GlobalContextInterface {
  community?: string;
  communityName?: string;
  updates: CUpdates;
  setUpdates: (update: CUpdates) => void;
  userIsAway: boolean;
}

const GlobalContext = createContext<GlobalContextInterface | undefined>(
  undefined
);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
 
  return context;
};

export default GlobalContext;