import { SelectProvider } from "@mui/base";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useNavigation, useParams } from "react-router";
import { useAuthContext } from "./AuthContext";

import GlobalContext from "./GlobalContext";
import { CUpdates } from "../Model";




export const GlobalProvider:React.FC<{children: React.ReactNode}> = ({children}) => {
  const [ community, setCommunity ] = useState<string | undefined>();
  const [ communityName, setCommunityName ] = useState<string | undefined>();
  const [ updates, setUpdates ] = useState<CUpdates>({ dm: {} });
  const [ userIsAway, setUserIsAway ] = useState<boolean>(false);

  const { auth, memberships } = useAuthContext();
  
  // we retrieve the selected community Id from the params and set the value globally so we can use it everywhere.
  let params = useParams();
  const communityIdFromParam = params.community;

  if (community !== communityIdFromParam) {
    // we jumped to a place where we go outside the current community. We need to tell every component that.
    
    setCommunity(communityIdFromParam);

    const user = auth?.user;

    // lookup name too se we can use it in our header, know to show a footer and such global states
    if (memberships && user) {
      const member = memberships.find( (m) => m.community._id == communityIdFromParam );
      setCommunityName(member?.community.name);
    }
  }

  const onVisibilityChanged = (e:any) => {
    e.preventDefault();
    const away = document.visibilityState !== 'visible';
    setUserIsAway(away);

    console.log(away?"User is away.":"User is back");
  };

  useEffect(() => {
      window.addEventListener('visibilitychange', onVisibilityChanged);
      return () => {
          window.removeEventListener('visibilitychange', onVisibilityChanged);
      };
  }, []);


  return ( 
    <GlobalContext.Provider value={{ community: community, communityName, updates, setUpdates, userIsAway }}>
      {children}
    </GlobalContext.Provider>
  );
};