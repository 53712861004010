import * as React from 'react';
import { useContext } from 'react';
import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BookRoundedIcon from '@mui/icons-material/BookRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { CredentialResponse, GoogleCredentialResponse, GoogleLogin, googleLogout } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useAuthContext, LOCAL_STORAGE_TOKEN_NAME } from '../Context/AuthContext';
import LogoFull from './LogoFull';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../Context/GlobalContext';
import { GlobalStyles } from '@mui/joy';






export default function Header( { minimal = false }: { minimal?: boolean } ) {
  const {authenticated, authGoogle, auth, setAuthenticated, memberships} = useAuthContext();
  const { mode, setMode } = useColorScheme();

  const navigate = useNavigate();

  const logout = () => {
    console.log("Logout clicked.");

    googleLogout();
    setAuthenticated(false);
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);

    navigate("/");
  };
  
  const googleLoginSuccess = async (credentialResponse: GoogleCredentialResponse) => {
    const res = await authGoogle( credentialResponse.credential! );
    
    // login succesful. There is a lot of reloading to do


  };

  const { community, communityName } = useGlobalContext();
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >

    <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
          },
        })}
      />

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        alignItems: 'center',
      }}
    >
      {communityName?<LogoFull customText={communityName} />:<LogoFull/>}
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        alignItems: 'center',
      }}
    >

{!authenticated?
        <GoogleLogin
          onSuccess={credentialResponse => {
            googleLoginSuccess(credentialResponse);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          //useOneTap
          // auto_select
        />
:
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '40px', maxHeight: '40px', borderRadius: '9999999px' }}
          >
            <Avatar
            
              srcSet={ auth.image?.src }
              src={ auth.image?.src }
              sx={{ maxWidth: '40px', maxHeight: '40px' }}
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={ auth?.image?.src }
                  sx={{ borderRadius: '50%' }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    { auth?.name }
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    ___
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={() => {
              if (mode === 'light') {
                setMode('dark');
              } else {
                setMode('light');
              }
            }}>
              {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
              {mode === 'light' ? "Dark Mode": "Light Mode"}
            </MenuItem>
            <MenuItem>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={ () => { logout() } }>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
}
      </Box>
    </Box>
  );
}