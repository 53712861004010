import { Card, CardOverflow, CardActions, Box, Typography, Stack, Divider, AspectRatio, IconButton, Button, FormControl, Select, Input, Option, FormLabel, Sheet, Checkbox} from "@mui/joy";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CountrySelector from "../../components/CountrySelector";
import { useAuthContext } from "../../Context/AuthContext";
import { useCommunityContext } from "../../Context/CommunityContext";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from 'react';
import { CImage, CUser } from "../../Model";
import { UPLOAD_URL } from "../../_Constants/Generic";

const LOAD_PROFILE = gql`
  query OnMyUser {
    myUser {
      _id
      name
      firstName
      lastName
      about

      image { 
        _id
        src # generated image signed image url
      } 
      language
      country
      timezone
    }
  }
`;

const SAVE_PROFILE = gql`
  mutation OnUpdateMyMembership($community:ID!, $name: String!, $image: String, $about: String) {
    updateMyMembership(community: $community, name: $name, image: $image, about: $about)
  }

`;

export const Profile = () => {

  const { auth } = useAuthContext();
  const { community, membership } = useCommunityContext();
  const [ myUser, setMyUser ] = useState<CUser>();

  const [ copyFromAccount, setCopyFromAccount ] = useState<boolean>(true);

  const { loading, data } = useQuery(LOAD_PROFILE, { variables: { community } } );
  const [ saveProfile ] = useMutation(SAVE_PROFILE, { variables: { community } } );
  const [ name, setName ] = useState<string>(membership.name);
  const [ image, setImage ] = useState<CImage | undefined>(membership.image);
  const [ about, setAbout ] = useState<string | undefined>(membership.about?membership.about:"");
  const [ processing, setProcessing ] = useState<boolean>(false);

  const [ changed, setChanged ] = useState<boolean>(false);

  useEffect( () => {
  if (!loading && data && data.myUser) {
    setMyUser(data.myUser);
    }
  }, [data, loading])

  useEffect( () => {
    setCopyFromAccount(isDifferentThanAccount()) 
  }, [ myUser, name, about, image])



  const onChangeCopyFromAccount = (event:any) => {
    

    if (event.target.checked && myUser) {
      setName(myUser.name);
      setImage(myUser.image);
      setAbout(myUser.about?myUser.about:"");
      setChanged(true);
      setCopyFromAccount(event.target.checked);
    }

    
  }
  const isDifferentThanAccount = () => {
    return (myUser?.name === name && 
      ( (!myUser?.about && about === "") || myUser?.about == about) && 
      myUser?.image?._id === image?._id);
  }

  const onCancel = () => {
    setName(membership.name);
    setImage(membership.image);
    setAbout(membership.about?membership.about:"");
    setChanged(false);
  }

  const onSave = async () => {
    await saveProfile( { variables: { name: name.trim(), image: image?._id, about: about?.trim() } } );
    setChanged(false);
  }

  const onUploadAvatar = async (e:any) => {
    const file = e.currentTarget.files[0];
    const token = localStorage.getItem('token');

    setProcessing(true);
    
    const formData = new FormData();
    formData.append("file", file);
    try {
    
      
      const result = await fetch(UPLOAD_URL, {
        method: "POST",
        body: formData,
        headers: {
          authorization: token ? `Bearer ${token}` : ""
        }
      });

      const data = await result.json();

      if (data.images && data.images.length>0) {
        // upload succeeded
        const changedAvatar = data.images[0];
        setImage(changedAvatar);
        setChanged(true);
        return;
      } else {
        alert("Error uploading image.");
      }
    } catch (e) {
      console.error("Upload error", e);
    } finally {
      setProcessing(false);
    }
  }
  


  return (
<Sheet
  sx={{
    width:'100vw',
    height: { xs: 'calc(100dvh - var(--Header-height) - var(--Footer-height))', lg: '100dvh' },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background.level1',
  }}
>
{loading?"":(
  <Stack direction="column" width="100%">
    <Card sx={{m:3}}>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Personal info</Typography>
            <Typography level="body-sm">
              Customize how your profile information will appear in this community.
            </Typography>

            {/* <Stack direction="row" justifyContent="end" width="100%">
              <Checkbox size="sm" checked={copyFromAccount} label="Same as my Account" onChange={onChangeCopyFromAccount} />
            </Stack> */}

          </Box>
          <Divider />
          <Stack
            direction="column"
            spacing={2}
            sx={{ display: { xs: 'flex' }, my: 1 }}
          >
            <Stack direction="row" spacing={2}>
              
              <Stack direction="column" spacing={1}>

                <AspectRatio
                  ratio="1"
                  maxHeight={108}
                  sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
                >
                  <img
                    style={processing?{opacity: 0}:{}}
                    src={processing?undefined:image?.src}
                    loading="lazy"
                    alt=""
                  />
                </AspectRatio>
                {processing?undefined:
                <IconButton
                  component="label"
                  aria-label="upload new picture"
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  sx={{
                    bgcolor: 'background.body',
                    position: 'absolute',
                    zIndex: 2,
                    borderRadius: '50%',
                    left: 85,
                    top: 180,
                    boxShadow: 'sm',
                  }}
                >
                  <EditRoundedIcon />
                  <Input
                  
                  sx={{display:"none"}}
                  type="file"
                  hidden
                  onChange={onUploadAvatar}
                  name="file"
                />
                </IconButton>}
              </Stack>
              <Stack spacing={1} sx={{ flexGrow: 1 }}>
                
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input size="sm" placeholder="Name" value={name} onChange={ (e) => { setName(e.target.value); setChanged(true); }}/>
                </FormControl>
                
              </Stack>
            </Stack>
            <FormControl>
              <FormLabel>About me</FormLabel>
              <Input size="sm" placeholder="About me" value={about} onChange={ (e) => { setAbout(e.target.value); setChanged(true); } } />
            </FormControl>
            
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }} >
              <Button size="sm" variant="outlined" color="neutral" disabled={!changed} onClick={onCancel}>
                Cancel
              </Button>
              <Button size="sm" variant="solid" disabled={!changed} onClick={onSave}>
                Save
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>

  </Stack>
)}
</Sheet>
  )
}