import { createContext, useContext } from "react";
import { CMember, MemberStatus } from "../Model";
// import { Membership } from "../Model/clientOnlyModel";

enum ROLES {
  ADMIN = 'admin',
}

interface CommunityContextInterface {
  community: string;
  role?: ROLES;
  isAdmin: boolean;
  membership: CMember;
  onlineMembers: { [memberId:string]:MemberStatus }; // members that are online now
  
  getOnlineStatus: (memberId: string) => MemberStatus | undefined;
  changeOnlineStatus: ( { online } : { online: boolean }) => Promise<void>;
}

const CommunityContext = createContext<CommunityContextInterface | undefined>(
  undefined
);

export const useCommunityContext = () => {
  const context = useContext(CommunityContext);
  if (context === undefined) {
    throw new Error("useCommunityContext must be used within a CommunityProvider");
  }

  
  return context;
};

export default CommunityContext;