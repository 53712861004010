import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { Box, Card, Divider, Grid, List, Stack, Typography } from "@mui/joy";
import { BoxProps } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from 'react-router';
import CommunityHomeCard from "../../components/CommunityHomeCard";
import CreateContentItemForm from "../../components/CreateContentItemForm";
import * as _ from 'lodash';
import { SET_ONLINE_STATUS_INTERVAL } from "../../Const";
import { CommunityProvider } from "../../Context/CommunityProvider";
import { useCommunityContext } from "../../Context/CommunityContext";
import { Home } from "./Home";
import { Route, Routes } from "react-router-dom";
import { Messages } from "./Messages";
import { Members } from "./Members";
import { Member } from "./Member";
import { Profile } from "./Profile";
import { Invites } from "./Invites";


// This is the wrapper Component that manages all the subscriptions and online status intervals
// when working with one community.

export function Community(props: BoxProps) {
  
  // useEffect(() => {
  //   console.log("community MOUNT");
  //   return () => { console.log("community UNMOUNT")}
  // });
  return (
    
<CommunityProvider>
  <div style={{}}>
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        
        position:"absolute"
      }}>
      <Routes>
        <Route path="" element={<Home />}></Route>
        <Route path="messages" element={<Messages />}></Route>
        <Route path="members" element={<Members />}></Route>
        <Route path="members/invites" element={<Invites />}></Route>
        <Route path="members/:member" element={<Member />}></Route>
        <Route path="profile" element={<Profile />}></Route>
      </Routes>
    
    </Box>


  </div> 
</CommunityProvider>
);}