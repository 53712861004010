import * as React from 'react';
import Box, { BoxProps } from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import { useGlobalContext } from '../Context/GlobalContext';

function Root(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          bgcolor: 'background.appBody',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
            md: 'minmax(160px, 300px) minmax(300px, 500px) minmax(500px, 1fr)',
          },
          gridTemplateRows: '64px 1fr',
          minHeight: '100vh',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function Header(props: BoxProps) {
  return (
    <Box
      component="header"
      className="Header"
      {...props}
      sx={[
        {
          p: 2,
          gap: 2,
          bgcolor: 'background.surface',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'fixed',
          width: '100vw',
          top: 0,
          zIndex: 1100,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}


function Footer(props: BoxProps) {
  const { community } = useGlobalContext();

  const isCommunity = !!community;
 
  return (
   <React.Fragment>
     {isCommunity ? 
    <Box
      component="footer"
      className="footer"
      {...props}
      sx={[
        { 
          p: 0,
          gap: 2,
          bgcolor: 'background.surface',
          display: 'flex',
          
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gridColumn: '1 / -1',
          borderBottom: '1px solid',
          borderTop: '1px solid',
          borderColor: 'divider',
          position: 'fixed',
          width: '100vw',
          bottom: 0,
          left: 0,
          zIndex: 1100,
        
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    /> : undefined }
    </React.Fragment>
  );
}


function Main(props: BoxProps) {
  return (

    <div style={{width:"100%"}}>
      <Box sx={[{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'left',
          overflow: "auto",
          marginTop: 10,
          boxSizing: "content-box",
          
      }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}

        
        {...props}
        />

      </div>
  );
}


export default {
  Root,
  Header,
  Footer,
  Main,
};