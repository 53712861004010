import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Chip from '@mui/joy/Chip';
import Grid from '@mui/joy/Grid';

type MyProps = {
  // using `interface` is also ok
  name: string;
  description: string;
  description2: string;
  image: string; 
  onClick: React.MouseEventHandler;
};

type MyState = {
 
};

export default class CommunityTemplateCard extends React.Component<MyProps, MyState>
{
  render() {
  return (
    <Grid>
    <Card
      variant="outlined"
      sx={{
        width: 320,
        // to make the card resizable
        overflow: 'auto',
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
       
      </Box>
      <CardContent>
        <Typography level="title-lg">{this.props.name}</Typography>
        <Typography level="body-sm">
          {this.props.description}
        </Typography>
        <Chip
          variant="outlined"
          color="primary"
          size="sm"
          sx={{ pointerEvents: 'none' }}
        >
          {this.props.description2}
        </Chip>
      </CardContent>
      <CardActions buttonFlex="0 1 120px">
        <IconButton variant="outlined" color="neutral" sx={{ mr: 'auto' }}>
          <FavoriteBorder />
        </IconButton>
       
        <Button variant="solid" color="primary" onClick={this.props.onClick}>
          Create
        </Button>
      </CardActions>
    </Card>
    </Grid>
  );
}
}