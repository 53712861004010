import { gql, useMutation, useQuery } from "@apollo/client";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Avatar, AvatarGroup, Button, Grid, Input, List, Sheet, Stack, Switch, Table, Typography } from "@mui/joy";
import { Box, BoxProps } from "@mui/joy";
import * as _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
import { useCommunityContext } from "../../Context/CommunityContext";
import CommunityMemberCard from "../../components/CommunityMemberCard";
import { CInvite, CMember } from "../../Model";
import InviteMemberForm from "../../components/InviteMemberForm";
import InviteMemberCard from "../../components/InviteMemberCard";
import { CardMembership, CheckCircleOutlined, SearchOffRounded } from "@mui/icons-material";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { useEffect, useState } from "react";
import React from "react";
import { AnimatedListItem } from "../../components/AnimatedListItem";
import { useAuthContext } from "../../Context/AuthContext";
import InviteMoreCard from "../../components/InviteMoreCard";
import Divider from '@mui/joy/Divider';
import InviteIcon from '@mui/icons-material/EmailRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { relativeDate, shortDate } from "../../utils";

const INVITES_QUERY = gql`
  query OnInvites($community: ID!) {
    invites(community: $community) {
      _id
      name
      email
      message
      createdAt
      status
    }
  }
`;

const CREATE_INVITE = gql`
  mutation inviteMember($community: ID!, $email:String!, $name:String, $message: String) {
    inviteMember (community: $community, email: $email, name: $name, message: $message) {
      _id
    }
  }
`;

const DELETE_INVITE = gql`
  mutation OnDeleteInvite($community: ID!, $invite: ID!) {
    deleteInvite(community: $community, invite: $invite)
  }
`;


export function Invites(props: BoxProps) {
  const { auth } = useAuthContext();
  const { community, getOnlineStatus, membership, isAdmin} = useCommunityContext();
  const refetchQueries = () => [{ query: INVITES_QUERY, variables: { community } }];

  const { loading: loadingInvites, data:dataInvites } = useQuery(INVITES_QUERY, { variables: { community } } );
  const [ invites, setInvites ] = useState<CInvite[]>([]);
  const [ createInvite, { data, loading, error }] = useMutation(CREATE_INVITE, { variables: { community }, refetchQueries } );
  
  const [ deleteInvite ] = useMutation(DELETE_INVITE, { variables: { community }, refetchQueries } );
  
  const memberId = membership._id;

  const [ index, setIndex ] = useState<number>(-1);

  useEffect( () => {
    if (!loadingInvites && dataInvites && dataInvites.invites) {
        setInvites(dataInvites.invites);
      }
  }, [ dataInvites ]);

  
  async function onDeleteInvite(invite: CInvite) {
    //const inviteId = event.currentTarget.value;

    console.log("Start to delete invite", invite);
    await deleteInvite( { variables: { invite: invite._id } } );
    console.log("deleted!");
  }

  async function onInvite( { email, name, message }: { email: string, name: string, message?: string}) {
    if (message === "") message = undefined;
    await createInvite( { variables: { email, name, message } } );

  }

  return (
    <Sheet
      sx={{
        width:'100vw',
        height: { xs: 'calc(100dvh - var(--Header-height) - var(--Footer-height))', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
        
      }}
    >
    <Stack direction="column" width="100%" sx={{paddingBottom:'5em'}}>
     
   

    <AccordionGroup
  color="neutral"
  size="md"
  variant="plain"
>
{invites? invites.map( (invite, i) =>    
  <Accordion key={invite._id} expanded={index === i}
  onChange={(event, expanded) => {
    setIndex(expanded ? i : -1);
  }}>
    <AccordionSummary>
      
      <Typography color="neutral" fontSize="md">
        {invite.email}
      </Typography>
      
      <Typography color="neutral" fontSize="xs" style={{position:'absolute', right:'3em'}}>{relativeDate(invite.createdAt)}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Stack direction="column">
        {invite.message?<Typography fontSize="sm">"{invite.message}"</Typography>:undefined}
        <br/>
        <Stack direction="row" width="100%" alignContent="end" justifyItems="end" spacing={2} sx={{p:1}}>
          <Button variant="outlined" color="neutral">Resend</Button>
          <Button variant="outlined" color="neutral" onClick={() => onDeleteInvite(invite)}>Delete</Button>
        </Stack>
      </Stack>
    </AccordionDetails>
  </Accordion>
): undefined }
</AccordionGroup>

<Divider/>

<InviteMemberForm onInvite={onInvite} />
    

    </Stack>
  </Sheet>
); }