let apiHost = process.env.REACT_APP_APIHOST;
if (!apiHost) apiHost = 'http://localhost:4000';

export const GRAPHQL = apiHost + '/graphql';
export const WSLINK = apiHost.replaceAll('http://', "ws://").replaceAll('https://','wss://') + '/subscriptions';

export const UPLOAD_URL = apiHost + '/upload';


export const DARK_STRIPED_BACKGROUND = 'repeating-linear-gradient(-45deg, #202020, #202020 5px, #404040 5px, #404040 10px)';
export const LIGHT_STRIPED_BACKGROUND = 'repeating-linear-gradient(-45deg, #e0e0e0, #e0e0e0 5px, #C0C0C0 5px, #C0C0C0 10px)';
