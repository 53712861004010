import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Autocomplete from '@mui/joy/Autocomplete';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import ChipDelete from '@mui/joy/ChipDelete';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import Slider from '@mui/joy/Slider';
import Sheet from '@mui/joy/Sheet';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';

import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom';
import {Navigate, useRoutes} from 'react-router-dom';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import Layout from './components/Layout';
import Header from './components/Header';

import { AuthContext, useAuthContext } from './Context/AuthContext';
import { Home } from './Pages/Home';
import { Community } from './Pages/Community/_Index';
import CreateCommunity from './Pages/CreateCommunity';

import { Invite } from './Pages/Invite/Invite';
import { AppLayout } from './Pages/Layouts';

export default function App() {


  const { authenticated } = useAuthContext();
  

  return (
    
  <React.Fragment>
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={ <Home /> }></Route>
        <Route path="/c/new" element={ <CreateCommunity /> }></Route>
        <Route path="/invite/:invite" element={ <Invite /> }></Route>
      
        <Route path="/c/:community/*" element={ <Community /> }></Route>
      </Route>

    
    </Routes>
  </React.Fragment>

  );
}

