import { gql, useMutation, useQuery } from "@apollo/client";
import { AspectRatio, Button, Card, CardActions, CardOverflow, Checkbox, Divider, Grid, IconButton, Input, List, Sheet, Stack, Switch, Typography } from "@mui/joy";
import { Box, BoxProps } from "@mui/joy";
import * as _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
import { useCommunityContext } from "../../Context/CommunityContext";
import CommunityMemberCard from "../../components/CommunityMemberCard";
import { CInvite, CMember } from "../../Model";
import InviteMemberForm from "../../components/InviteMemberForm";
import InviteMemberCard from "../../components/InviteMemberCard";
import { CardMembership, CheckCircleOutlined, SearchOffRounded } from "@mui/icons-material";
import SearchRounded from "@mui/icons-material/SearchRounded";
import { useEffect, useState } from "react";
import React from "react";
import { AnimatedListItem } from "../../components/AnimatedListItem";
import { useAuthContext } from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import MessagesPane from "../../components/MessagesPane";
import MessagesPaneHeader from "../../components/MessagesPageHeader";
import { fullDate, shortDate } from "../../utils";
import AvatarWithStatus from "../../components/AvatarWithStatus";


const MEMBER_QUERY = gql`
  query GetMember($community: ID!, $member: ID!) {
    communityMember(community: $community, member: $member) {
      _id
      name
      about
      role
      joinedAt
      image { 
        src
      }
      online
    }
  }
`;

const CHANGE_ROLE = gql`
  mutation ChangeRole($community: ID!, $member: ID!, $role: String) {
    setRole(community: $community, member: $member, role: $role)
  }
`;

export function Member(props: BoxProps) {
  const { auth } = useAuthContext();
  const { community, getOnlineStatus, membership, isAdmin} = useCommunityContext();

  const params = useParams();
  const memberId = params.member;
  
  const { loading, data } = useQuery(MEMBER_QUERY, { variables: { community, member: memberId  } } );
  const [ changeRole ] = useMutation(CHANGE_ROLE, { variables: { community, member: memberId } } );
  const [ member, setMember ] = useState<CMember>();
 
  useEffect( () => {

    if (!loading && data?.communityMember) {
      setMember(data.communityMember);
    }
  }, [ loading, data ]);

  async function onChangeAdministrator(event: any) {
    if (!member) return;

    const role = event.target.checked ? 'admin' : undefined;

    const result = await changeRole( { variables: { role } } );
    console.log("result of mutation", result);
    
    const copy: CMember = { ...member };
    if (role === 'admin') copy.role = 'admin'; else delete copy.role;
    setMember(copy);
  }

  return (
    // <Sheet
    //   sx={{
    //     width:'100vw',
    //     height: { xs: 'calc(100dvh - var(--Header-height) - var(--Footer-height))', lg: '100dvh' },
    //     display: 'flex',
    //     flexDirection: 'column',
    //     backgroundColor: 'background.level1',
        
        
    //   }}
    // >
    //   {member?<MessagesPaneHeader 
    //     member={member} 
    //     online={getOnlineStatus(member._id.toString())?.online}/>:undefined}

    // </Sheet>

<Sheet
  sx={{
    width:'100vw',
    height: { xs: 'calc(100dvh - var(--Header-height) - var(--Footer-height))', lg: '100dvh' },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'background.level1',
  }}
>
{!member?"":(
  <Stack direction="column" width="100%">
    <Card sx={{m:3}}>
      <Box sx={{ mb: 1 }}>
        
        <Typography level="title-md">Member</Typography>
        <Typography level="body-sm">
          {member?.name} is member since {shortDate(member?.joinedAt)}.
        </Typography>
       

      </Box>
      <Divider />
      <Stack
        direction="column"
        spacing={2}
        sx={{ display: { xs: 'flex' }, my: 1 }}
      >
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={1}>

            <AspectRatio
              ratio="1"
              maxHeight={108}
              sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
            >
              <img
                src={member?.image?.src}
                loading="lazy"
                alt=""
              />
            </AspectRatio>
            
          </Stack>
          <Stack spacing={1} sx={{ flexGrow: 1 }}>
            <Typography>
              {member?.name}      
            </Typography> 
            <Typography>
              {member?.about}      
            </Typography> 
            
          </Stack>
          <Checkbox size="sm" checked={(member.role == 'admin')} label="administrator" onChange={onChangeAdministrator} />
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
    
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }} >
              
              <Button size="sm" variant="solid" color="danger" >
                Remove
              </Button>
              <Button size="sm" variant="solid" >
                Message
              </Button>
            </CardActions>
          </CardOverflow>
      </Stack>
    </Card>
  </Stack>)}
</Sheet>
      );
    }