import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './Context/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, Operation, split } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import moment from 'moment';
import 'moment/locale/nl';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { GRAPHQL, WSLINK } from './_Constants/Generic';

console.log("Connecting to", { GRAPHQL, WSLINK });
// console.log("All ENV", process.env);

moment.locale("nl"); // make variable

function getAuthorizationHeader() {
  const token = localStorage.getItem('token');
  return { authorization: token ? `Bearer ${token}` : "" };
}

moment.locale("nl"); // make variable

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...getAuthorizationHeader()
    }
  }
});

const httpLink = createHttpLink({
  uri: GRAPHQL
});


const wsLink = new GraphQLWsLink(
  createClient({
    url: WSLINK,
    connectionParams: () => { return getAuthorizationHeader() }
  }));

const splitLink = split(
  (operation:Operation) => {
    const definition = getMainDefinition(operation.query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink, // web socket connection for subscriptions
  authLink.concat( httpLink ) // http connection for query and mutation
);


const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId="752468829557-jpckralutegbk7n1in2vh3r4n795fro4.apps.googleusercontent.com">
          <AuthProvider>
            <App />
          </AuthProvider>
        </GoogleOAuthProvider>
      </BrowserRouter>
    </ApolloProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
